import {PartialObserver} from "rxjs";
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";

export const DefaultTags = {
  strengthTag: "test2",
  enduranceTag: "test2",
  balanceTag: "test2",
  flexibilityTag: "test2",
  exerciseTags: "VS300, VS300-2, VimHome",
  approvedOnly: false,
}

export interface ReportRunningComponent {
  busy: boolean
  output: string
}

const CONTENT_TYPE_EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function runReport(name: string, page: ReportRunningComponent): PartialObserver<any> {
  page.busy = true;

  return {
    next: (value: HttpEvent<any>) => {
      console.log("HttpEventType", value.type);

      if (value.type == HttpEventType.Sent) {
      } else if (value.type == HttpEventType.UploadProgress) {
      } else if (value.type == HttpEventType.ResponseHeader) {
        page.busy = false;
      } else if (value.type == HttpEventType.DownloadProgress) {
      } else if (value.type == HttpEventType.Response) {
      }

      if (value instanceof HttpResponse) {
        const contentType = value.headers.get("Content-Type")
        console.log("HttpResponse: status =", value.status, ", contentType =", contentType)

        page.busy = false;

        let filename = 'programs.xlsx';
        const cd = value.headers.get("content-disposition");
        if (cd) {
          const m = cd.match("filename=\"\([^\"]*\)\"");
          if (m) {
            filename = m && m[1];
          }
        }

        let href;
        const location = value.headers.get("Location");
        if ((location || "").length > 0) {
          href = location;
        }

        console.log("contentType", contentType);

        if (contentType == CONTENT_TYPE_EXCEL) {
          const binaryData = [value.body];
          href = window.URL.createObjectURL(new Blob(binaryData, {type: null}));
        } else {
          page.output = value.body;
        }

        if (href) {
          const a = document.createElement('a');
          a.href = href;
          a.setAttribute("download", filename);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    },
    error: (error) => {
      console.log("error", error);
      let msg: string
      if (error instanceof HttpErrorResponse) {
        console.log("ERROR: HttpEventType", error);
        msg = error.error.message;
      }

      page.busy = false;
      if (msg != null) {
        page.output = msg;
      } else {
        page.output = "Unknown failure"
      }
    }
  }
}
