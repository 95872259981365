<p>
  This exercise picks exercises for each available program. The "picks" parameter determines how many time each
  capacity + cell duration combination is run.
</p>

<form>
  <p>
    <mat-form-field>
      <mat-label>Strength picks</mat-label>
      <input matInput type="text" [(ngModel)]="pe.strengthPicks" name="peStrengthPicks">
    </mat-form-field>

    <span class="spacer"></span>

    <mat-form-field>
      <mat-label>Strength</mat-label>
      <input matInput type="text" [(ngModel)]="pe.strengthTag" name="peStrengthTag">
    </mat-form-field>
    <a href="#" (click)="pe.strengthTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="pe.strengthTag = 'test-2'; false">test-2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Endurance picks</mat-label>
      <input matInput type="text" [(ngModel)]="pe.endurancePicks" name="peEndurancePicks">
    </mat-form-field>

    <span class="spacer"></span>

    <mat-form-field>
      <mat-label>Endurance</mat-label>
      <input matInput type="text" [(ngModel)]="pe.enduranceTag" name="peEnduranceTag">
    </mat-form-field>
    <a href="#" (click)="pe.enduranceTag = 'vs-300-endurance'; false">vs-300-endurance</a>&nbsp;
    <a href="#" (click)="pe.enduranceTag = 'vs-300'; false">vs-300</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Balance picks</mat-label>
      <input matInput type="text" [(ngModel)]="pe.balancePicks" name="peBalancePicks">
    </mat-form-field>

    <span class="spacer"></span>

    <mat-form-field>
      <mat-label>Balance</mat-label>
      <input matInput type="text" [(ngModel)]="pe.balanceTag" name="peBalanceTag">
    </mat-form-field>
    <a href="#" (click)="pe.balanceTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="pe.balanceTag = 'test2'; false">test2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Flexibility picks</mat-label>
      <input matInput type="text" [(ngModel)]="pe.flexibilityPicks" name="peFlexibilityPicks">
    </mat-form-field>

    <span class="spacer"></span>

    <mat-form-field>
      <mat-label>Flexibility</mat-label>
      <input matInput type="text" [(ngModel)]="pe.flexibilityTag" name="peFlexibilityTag">
    </mat-form-field>
    <a href="#" (click)="pe.flexibilityTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="pe.flexibilityTag = 'test2'; false">test2</a>
  </p>

  <p>
    <mat-form-field>
      <mat-label>Exercise tags</mat-label>
      <input matInput type="text" [(ngModel)]="pe.exerciseTags" name="peExerciseTags">
    </mat-form-field>
    <a href="#" (click)="pe.exerciseTags = ''; false">Blank</a>&nbsp;
    <a href="#" (click)="pe.exerciseTags = 'VS300'; false">VS300</a>&nbsp;
    <a href="#" (click)="pe.exerciseTags = 'VS300, VS300-2'; false">VS300 and VS300-2</a>
    <a href="#" (click)="pe.exerciseTags = 'VS300, VS300-2, VimHome'; false">VS300, VS300-2, VimHome</a>
    <a href="#" (click)="pe.exerciseTags = 'VimHome'; false">VimHome</a>
  </p>

  <p class="environment-list">
    <label id="environment-list-label">Environment</label>
    <mat-radio-group [(ngModel)]="pe.environment" name="peEnvironment">
      <mat-radio-button [value]=""><em>None</em></mat-radio-button>
      <mat-radio-button *ngFor="let environment of environments" [value]="environment">{{environment}}</mat-radio-button>
    </mat-radio-group>
  </p>

  <button mat-button type="button" (click)="setPicks(0)">Set "picks" to 0</button>
  <button mat-button type="button" (click)="setPicks(1)">Set "picks" to 1</button>
  <button mat-button type="button" (click)="setPicks(10)">Set "picks" to 10</button>

  <button
    mat-button type="submit"
    [disabled]="busy"
    (click)="runPickExercises()">
    Create report

    <app-spin [active]="busy"></app-spin>
  </button>
  <span *ngIf="output">{{ output }}</span>
</form>
